/* eslint-disable @next/next/no-html-link-for-pages */
import styles from "../styles/Home.module.css";
import {
  Box,
  Button,
  Center,
  SimpleGrid,
  Heading,
  Flex,
  Spacer,
  Square,
  Badge,
} from "@chakra-ui/react";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import { Typeahead, withAsync } from "react-bootstrap-typeahead";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { GetCookie } from "../provider/common";
import { authLogout } from "../provider/auth";
import { useRecoilState } from "recoil";
import { cartCntState, estimateCntState, quoteCntState } from "../atoms/state";
import { GetCartCnt, GetQuoteCnt } from "../pages/api/cart";
import { getEstimateDataCnt } from "../pages/api/estimate";
const axios = require("axios").default;

const AsyncTypeahead = withAsync(Typeahead);

export default function Header(props) {
  const router = useRouter();
  const { keyword } = router.query; //미리 할당을 해줘야 처리됨

  const [inputKeyword, setInputKeyword] = useState(keyword);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [cartBadge, setCartBadge] = useState("red");
  const [cartCnt, setCartCnt] = useRecoilState(cartCntState);
  const [quoteCnt, setQuoteCnt] = useRecoilState(quoteCntState);
  const [estimateCnt, setEstimateCnt] = useRecoilState(estimateCntState);
  const [marketPlace, setMarketPlace] = useState("");
  const [sellerPageState, setSellePageStage] = useState("");
  const [reload, setReload] = useState("");

  useEffect(() => {
    isLoginCheck();
    showCnt();
    // 현재 판매자 페이지 상태값을 따로 저장한다.
    changeSellerPageState();

    if (router.pathname === "/marketplace/market") {
      setMarketPlace("marketPlace");
    }

    if (router.pathname === "/") {
      setReload("start");
    }
  }, []);

  const locationReload = () => {
    window.location.reload();
  };

  const checkApproved = async () => {
    const approvedApi = process.env.PANDA_API_URL + "/api/auth/sellerDecode";
    const token = await GetCookie("token");
    const approvedRes = await axios.post(
      approvedApi,
      {
        token: token,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    const approvedCondition = approvedRes.data.data;
    if (approvedCondition === "not_login") {
      alert("로그인 후 이용해주세요.");
      const href = `/auth/login`;
      router.push(href);
    } else if (approvedCondition === "submitted") {
      alert("승인 대기중입니다.\n영업일 기준 1일내로 연락드리겠습니다.");
    } else if (approvedCondition === "not_approved") {
      const href = "/board/register";
      router.push(href);
    } else if (approvedCondition === "approved") {
      const href = "/seller/dashboard";
      router.push(href);
    }
  };

  const changeSellerPageState = async () => {
    const approvedApi = process.env.PANDA_API_URL + "/api/auth/sellerDecode";
    const token = await GetCookie("token");
    const approvedRes = await axios.post(
      approvedApi,
      {
        token: token,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    setSellePageStage(approvedRes.data.data);
  };

  async function isLoginCheck() {
    const isLogin = await GetCookie("isLogin");
    setIsLogin(Number(isLogin));
  }

  const showCnt = async () => {
    setCartCnt(await GetCartCnt());
    setQuoteCnt(await GetQuoteCnt());
    const estimateCnt = await getEstimateDataCnt();
    setEstimateCnt(estimateCnt);
  };

  // 로그아웃 기능
  const _handleLogout = async () => {
    await authLogout();
  };

  const handleCategoryMouseOver = function (param) {
    // samp();
    getNextCatgNodes(param, process.env.PANDA_API_URL);
  };

  const _handleAutocomplete = (query) => {
    const enc_query = encodeURIComponent(query);
    setisLoading(true);
    setInputKeyword(query);
    fetch(
      process.env.SEARCH_API_URL +
        `/search?keyword=${enc_query}&type=autocomplete`
    )
      .then((resp) => resp.json())
      .then(({ data }) => {
        const options = data.hits.hits.map((i) => ({
          id: Number(i._id),
          login: i._source.it_name,
          it_name: i._source.it_name,
        }));
        setOptions(options);
        setisLoading(false);
      });
  };

  const onClick = async (e) => {
    e.preventDefault();
    if (!inputKeyword) {
      alert("검색어를 입력하세요");
    } else {
      window.location.href =
        "/search/lists?keyword=" +
        (inputKeyword.startsWith("%")
          ? inputKeyword
          : encodeURIComponent(inputKeyword));
    }
    return;
  };

  const onChange = (e) => {
    setInputKeyword(e.target.value);
  };

  return (
    <>
      <Box className="header">
        <Box className="top-bar">
          <Box
            className="top-bar_box"
            variant="ghost"
            size="md"
            fontSize={14}
            p="0"
          >
            전자부품 온라인 구매 플랫폼 판다파츠
          </Box>
          {isLogin === 1 ? (
            <Box width="fit-content" className="flex-between">
              <Box width="fit-content" mr="15px">
                <Link href="/mypage/fast?path=마이페이지">마이페이지</Link>
              </Box>
              <Box width="fit-content" mr="15px" onClick={checkApproved}>
                <Link href="#">
                  {sellerPageState === "submitted" ||
                  sellerPageState === "approved"
                    ? "판매자페이지"
                    : sellerPageState === "not_approved"
                    ? "판매자신청"
                    : "판매자신청"}
                </Link>
              </Box>
              <Box width="fit-content" mr="15px">
                <Link
                  colorScheme="gray"
                  variant="ghost"
                  size="md"
                  href="/board/fast?status=FAQ"
                >
                  고객센터
                </Link>
              </Box>
              <Button
                onClick={() => _handleLogout()}
                className="logout-btn"
                width="fit-content"
              >
                로그아웃
              </Button>
            </Box>
          ) : isLogin === 0 ? (
            <Box width="fit-content" className="flex-between">
              <Box width="fit-content" mr="15px">
                <Link href="/auth/login">로그인</Link>
              </Box>
              <Box width="fit-content" mr="15px" onClick={checkApproved}>
                <Link href="/auth/login?before=/board/register">
                  판매자신청
                </Link>
              </Box>
              <Box width="fit-content" mr="15px">
                <Link href="/mypage/ordernum">주문확인</Link>
              </Box>
              <Box width="fit-content" mr="15px">
                <Link href="/board/fast?status=FAQ">고객센터</Link>
              </Box>
              <Box className="top-boar__register flex-center">
                <Link href="/auth/register">회원가입</Link>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box className="search-bar">
          <Box className="search-gnb">
            <Box style={{ float: "left", width: "240px" }}>
              {reload === "start" ? (
                <Box className={"header-logo cursor"} onClick={locationReload}>
                  <img src="/images/pandalogo.png" alt="로고" />
                </Box>
              ) : (
                <Box className={"header-logo cursor"}>
                  <Link href="/" className="panda-logo">
                    <img src="/images/pandalogo.png" alt="로고" />
                  </Link>
                </Box>
              )}
            </Box>
            <Square
              style={{
                float: "left",
                width: "500px",
                display: "block",
                position: "relative",
                marginRight: "20px",
              }}
            >
              <div>
                <AsyncTypeahead
                  id="panda_search"
                  isLoading={isLoading}
                  className="top-bar_search-input"
                  size="lg"
                  color="333"
                  h="50px"
                  minLength={3}
                  labelKey="login"
                  defaultInputValue={
                    inputKeyword
                      ? inputKeyword.startsWith("%")
                        ? decodeURIComponent(inputKeyword)
                        : inputKeyword
                      : ""
                  }
                  value={keyword}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (event.target.value) {
                        event.preventDefault();
                        window.location.href =
                          "/search/lists?keyword=" +
                          encodeURIComponent(event.target.value);
                      } else {
                        alert("검색어를 입력하세요");
                      }
                      setInputKeyword("");
                    }
                  }}
                  onChange={onChange}
                  onSearch={_handleAutocomplete}
                  options={options}
                  placeholder="전자부품 번호(P/N)로 검색하세요!"
                  renderMenuItemChildren={(option, props) => (
                    <div
                      onClick={() => {
                        window.location.href =
                          "/search/lists?keyword=" +
                          encodeURIComponent(option.it_name);
                      }}
                    >
                      <span>{option.it_name}</span>
                    </div>
                  )}
                >
                  <button onClick={onClick}>
                    <FontAwesomeIcon
                      className="top-bar_search-input__search-icon"
                      icon={faSearch}
                    />
                  </button>
                </AsyncTypeahead>
              </div>
              <Box className={`${styles.search_list}`} pr="10px">
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=IPW60R070C6"
                >
                  <Box textAlign="left" pl="10px">
                    IPW60R070C6
                  </Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=STM32G071G8U6"
                >
                  <Box widt="120px" pl="10px">
                    STM32G071G8U6
                  </Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=ISL8012IRZ-T"
                >
                  <Box width="90px">ISL8012IRZ-T</Box>
                </a>
                <a
                  className={`${styles.search_list_slide} cursor`}
                  href="/search/lists?keyword=SN74LVC07ARGYR"
                >
                  <Box>SN74LVC07ARGYR</Box>
                </a>
              </Box>
            </Square>

            <Spacer width="40px" height="100%" float="left"></Spacer>
            <Box width="300px" float="left">
              <Flex width="100%">
                <Flex
                  bg=""
                  height="fit-content"
                  flex="1"
                  justifyContent="flex-end"
                >
                  <Box
                    bg=""
                    width="fit-content"
                    height="100%"
                    pt="10px"
                    className="cursor"
                  >
                    <Link href="/estimate/fast_lists">
                      <Box
                        bg=""
                        h="fit-content"
                        color="white"
                        className="header-btn"
                      >
                        <Box>
                          {" "}
                          <img
                            src="/images/contract.png"
                            alt="견적내역"
                            width="32"
                            style={{
                              filter:
                                "invert(32%) sepia(0%) saturate(734%) hue-rotate(214deg) brightness(101%) contrast(93%)",
                            }}
                          ></img>
                          <Badge
                            colorScheme="red"
                            padding={2}
                            borderRadius={30}
                            marginLeft={1}
                          >
                            {estimateCnt}
                          </Badge>
                        </Box>
                        <Box>
                          <Heading
                            as="h6"
                            size="sm"
                            color="#555"
                            fontSize="13px"
                            p="3px 0"
                          >
                            견적내역
                          </Heading>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </Flex>
                <Flex
                  bg=""
                  height="fit-content"
                  flex="1"
                  justifyContent="flex-end"
                >
                  <Box
                    bg=""
                    width="fit-content"
                    height="100%"
                    pt="10px"
                    className="cursor"
                  >
                    <Link href="/marketplace/cart">
                      <Box
                        className="header-btn"
                        bg=""
                        h="fit-content"
                        color="white"
                      >
                        <Box>
                          <img
                            src="/images/quatation.png"
                            alt="견적바구니"
                            width="32"
                          ></img>
                          <Badge
                            colorScheme={cartBadge}
                            p="2"
                            borderRadius={20}
                            marginLeft={1}
                          >
                            {quoteCnt}
                          </Badge>
                        </Box>
                        <Box>
                          {" "}
                          <Heading
                            as="h6"
                            size="sm"
                            color="#555"
                            fontSize="13px"
                            p="3px 0"
                          >
                            견적바구니
                          </Heading>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </Flex>

                <Flex
                  bg=""
                  height="fit-content"
                  flex="1"
                  justifyContent="flex-end"
                >
                  <Box
                    bg=""
                    width="fit-content"
                    height="100%"
                    pt="10px"
                    className="cursor"
                  >
                    <Link href="/cart/view">
                      <Box
                        className="header-btn"
                        bg=""
                        h="fit-content"
                        color="white"
                      >
                        <Box>
                          {" "}
                          <img
                            src="/images/basket.png"
                            alt="장바구니"
                            width="32"
                          ></img>
                          <Badge
                            colorScheme={cartBadge}
                            p="2"
                            borderRadius={20}
                            marginLeft={1}
                          >
                            {cartCnt}
                          </Badge>
                        </Box>
                        <Box>
                          {" "}
                          <Heading
                            as="h6"
                            size="sm"
                            color="#555"
                            fontSize="13px"
                            p="3px 0"
                          >
                            장바구니
                          </Heading>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Box>
        {marketPlace === "marketPlace" ? (
          <Box
            borderTop="1px"
            borderBottom="1px"
            borderColor="#ededed"
            className="category-bar"
          ></Box>
        ) : (
          <Box
            borderTop="1px"
            borderBottom="1px"
            borderColor="#ededed"
            className="category-bar"
          >
            <Flex>
              <Box className={"header-link"}>
                <div className="category_mask" />
                <div className="category_wrap">
                  <input id="dpid_use_" type="hidden" />
                  <button
                    className="category_btn"
                    type="button"
                    onMouseOver={() => handleCategoryMouseOver("")}
                  >
                    카테고리
                  </button>
                  <ul className="category_list"></ul>
                </div>
              </Box>
              <Spacer />

              <Box className={"header-link"}>
                <Link
                  href="/marketplace/market"
                  style={{ width: "fit-content!important" }}
                >
                  마켓플레이스
                </Link>
              </Box>

              <Box className={"header-link"}>
                <Link
                  href="/parts/mallcategory/32"
                  style={{ width: "fit-content!important" }}
                >
                  오픈소스 보드
                </Link>
              </Box>

              <Box className={"header-link"}>
                <Link p="4" href="/board/fast_view?no=78&type=notice">
                  후불결제
                </Link>
              </Box>
              <Box className={"header-link"}>
                <Link p="4" href={"/estimate/fast"}>
                  판다봇 견적
                </Link>
              </Box>
              <Box className={"header-link"}>
                {/* <a p="4" href="/estimate/request?type=manual"> */}
                <Link
                  p="4"
                  href={
                    isLogin === 1
                      ? "/estimate/fast_request?type=manual"
                      : "/auth/login?before=/estimate/fast_request?type=manual"
                  }
                  style={{ cursor: "pointer" }}
                >
                  컴바인봇 견적
                </Link>
              </Box>
              <Box className={"header-link"}>
                <Link p="4" href="/board/fast?status=FAQ">
                  고객센터
                </Link>
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </>
  );
}
